import React, { useEffect, useRef, useState } from 'react'
import Fade from 'react-reveal/Fade'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import cx from 'classnames'
import ReCaptcha from 'react-google-invisible-recaptcha'

import CKAIT from '../images/ckait_color.png'
import linkedin from '../images/linkedin.svg'
import electro from '../images/electro_800.jpg'
import data from '../images/data_800.jpg'
import cctv from '../images/cctv_800.jpg'
import fire from '../images/fire_800.jpg'
import './index.scss'

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop + 30, left: 0, behavior: 'smooth' })

const InView = ({ children, onChange }) => {
    const [ ref, inView ] = useInView({ threshold: 1})

    useEffect(() => {
        if(inView) onChange()
    }, [inView])

    return <div ref={ref}>{children}</div>
}

const IndexPage = () => {
    const reCaptchaRef = useRef(null)
    const [active, setActive] = useState(null)
    const aboutRef = useRef(null)
    const servicesRef = useRef(null)
    const referencesRef = useRef(null)
    const contactRef = useRef(null)
    const [contactVisible, setContactVisible] = useState(false)
    const [scrollOffset, setScrollOffset] = useState(false)

    useEffect(() => {
        if(window) window.onscroll = () => setScrollOffset(window.pageYOffset > 70)
    }, [])

    const showContact = () => {
        document.body.classList.add('recaptcha-visible')
        reCaptchaRef.current.execute()
    }

    return (
        <main>
            <Helmet htmlAttributes={{lang: 'cs'}}>
                <title>Ing. Lukáš Jůzl - projektant elektro</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Ing. Lukáš Jůzl, projektant elektro, slaboproud, silnoproud, projektant eps, autorizace ČKAIT, projektant Zlín, Praha, Brno" />
                <meta name="description" content="Projektant elektro a autorizovaný inženýr ČKAIT v oboru technika prostředí staveb, specializace elektrotechnická zařízení" />
            </Helmet>
                <div className={`menu ${scrollOffset ? 'fixed' : ''}`}>
                    <div onClick={() => window.scrollTo({top: 0, behavior: 'smooth' })}>
                        <Fade down>
                            <h1>Ing. Lukáš Jůzl</h1>
                        </Fade>
                        <Fade down>
                            <h2>Projektant elektro</h2>
                        </Fade>
                    </div>
                    <ul>
                        <Fade left>
                            <li className={cx(active === 'about' && 'active')} onClick={() => scrollToRef(aboutRef)}>O mně</li>
                        </Fade>
                        <Fade left delay={100}>
                            <li className={cx(active === 'services' && 'active')} onClick={() => scrollToRef(servicesRef)}>Služby</li>
                        </Fade>
                        <Fade left delay={200}>
                            <li className={cx(active === 'references' && 'active')} onClick={() => scrollToRef(referencesRef)}>Reference</li>
                        </Fade>
                        <Fade left delay={300}>
                            <li className={cx(active === 'contact' && 'active')} onClick={() => scrollToRef(contactRef)}>Kontakt</li>
                        </Fade>
                    </ul>
                </div>
            <div className="container">

                <div className="head">
                    <div>
                        <Fade>
                            <h2>Autorizovaný inženýr ČKAIT v oboru technika prostředí staveb, specializace elektrotechnická zařízení</h2>
                        </Fade>
                    </div>

                    <div>
                        <Fade>
                            <h2>Projektová činnost ve výstavbě</h2>
                        </Fade>
                    </div>
                </div>

                <br />

                <div ref={aboutRef} />
                <Fade up>
                    <h3>O mně</h3>
                </Fade>

                <InView onChange={() => setActive('about')} />
                <Fade up>
                    <p>Zabývám se projektovou činností ve výstavbě, <strong>konkrétně slaboproudými zařízeními a rozvody na hladině nízkého napětí</strong>. Nabízím zpracování projektové dokumentace ve všech projekčních stupních.  Projektování se věnuji od roku 2013, po absolvování VŠ se zaměřením na slaboproud a bezpečnostní technologie. Od roku 2019 jsem členem České komory autorizovaných inženýrů a techniků činných ve výstavbě (ČKAIT).</p>
                </Fade>
                <br />

                <Fade up>
                    <p>Vlastním certifikáty opravňující k projekci mimo jiné těchto zařízení:</p>
                </Fade>

                <ul className="ul">
                    <Fade up>
                        <li>systémy EPS ZETTLER, Lites, Schrack Seconet, Honeywell ESSER</li>
                    </Fade>
                    <Fade up>
                        <li>evakuační rozhlas Honeywell VARIODYN D1</li>
                    </Fade>
                    <Fade up>
                        <li>zabezpečovací systémy Honeywell Galaxy, MB-Secure</li>
                    </Fade>
                    <Fade up>
                        <li>přístupový a docházkový systém Aktion</li>
                    </Fade>
                </ul>

                <Fade up>
                    <div className="images">
                        <a href="https://www.ckait.cz/" target="_blank" rel="noreferrer noopener">
                            <img src={CKAIT} alt="Česká komora autorizovaných inženýrů a techniků činných ve výstavbě" />
                        </a>
                    </div>
                </Fade>

                <div ref={servicesRef} />
                <Fade up>
                    <h3>Služby</h3>
                </Fade>

                <div className="services">
                    <Fade up>
                        <div className="service">
                            <InView onChange={() => setActive('services')} />
                            <h4>Bezpečnostní systémy</h4>
                            <p><strong>Poplachový zabezpečovací a tísňový systém (PZTS/EZS)</strong> slouží k detekci vniknutí do střeženého objektu a tuto informaci předává odpovědným osobám.</p>
                            <br />
                            <p>Elektronický <strong>přístupový systém (ACS)</strong> umožňuje zamezit vstup do vybraných prostor osobám bez platného oprávnění.</p>
                            <br />
                            <p><strong>Kamerový systém (CCTV)</strong> sleduje a zaznamenává pohyb osob a vozidel, případně technologické procesy.</p>
                            <div>
                                <img src={cctv} alt="Bezpečnostní systémy" />
                            </div>
                        </div>
                    </Fade>
                    <Fade up>
                        <div className="service">
                            <h4>Protipožární systémy</h4>
                            <p><strong>Elektrická požární signalizace (EPS)</strong> výrazně přispívá k ochraně života osob a majetku před požárem. Systém lze doplnit o zařízení <strong>evakuačního rozhlasu</strong> a tím efektivně řídit únik osob z objektu v případě nebezpečí.</p>
                            <div>
                                <img src={fire} alt="Protipožární systémy" />
                            </div>
                        </div>
                    </Fade>
                    <InView onChange={() => setActive('services')} />
                    <Fade up>
                        <div className="service">
                            <h4>Datové sítě/strukturovaná kabeláž</h4>
                            <p>Návrh <strong>metalické</strong> a <strong>optické kabelážní infrastruktury</strong> pro zajištění přenosu dat, hlasu a obrazu v rámci objektu.</p>
                            <div>
                                <img src={data} alt="Datové sítě/strukturovaná kabeláž" />
                            </div>
                        </div>
                    </Fade>
                    <Fade up>
                        <div className="service">
                            <h4>Rozvody nízkého napětí</h4>
                            <p><strong>Elektroinstalace 230V/400V</strong>, včetně schémat rozvaděčů, pro oblast školství, bytovou a administrativní výstavbu. Návrh <strong>vnitřní a vnější ochrany před bleskem</strong> (hromosvod) a uzemnění. Výpočty <strong>umělého a nouzového osvětlení</strong> podle aktuálních předpisů.</p>
                            <div>
                                <img src={electro} alt="Elektroinstalace" />
                            </div>
                        </div>
                    </Fade>
                </div>

                <div ref={referencesRef} />
                <Fade up>
                    <h3>Reference</h3>
                </Fade>

                <Fade up>
                    <p>Podílel jsem se na zpracování projektové dokumentace těchto staveb:</p>
                </Fade>

                <ul className="ul">
                    <Fade up>
                        <li><strong>Amazon</strong> - Průmyslový park Kojetín<br />silnoproud&nbsp;nn&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <Fade up>
                        <li><strong>Škoda Auto Mladá Boleslav</strong> - Nová lisovací linka v hale M12A<br />EPS&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <Fade up>
                        <li>Rekonstrukce historické a provozní budovy <strong>Státní opery v Praze</strong><br />EPS,&nbsp;slaboproud&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <Fade up>
                        <li><strong>Univerzita Karlova</strong>, 2. lékařská fakulta – Dostavba areálu Plzeňská<br />EPS,&nbsp;slaboproud&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <InView onChange={() => setActive('references')} />
                    <Fade up>
                        <li><strong>Kampus Albertov</strong> – Biocentrum, Globcentrum<br />EPS,&nbsp;slaboproud&nbsp;/&nbsp;DSP</li>
                    </Fade>
                    <Fade up>
                        <li>Ústav dějin Univerzity Karlovy a Archiv Univerzity Karlovy (ÚDAUK)<br />EPS,&nbsp;slaboproud&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <Fade up>
                        <li><strong>Univerzita Karlova</strong> - Rekonstrukce koleje Arnošta z Pardubic<br />EPS,&nbsp;slaboproud,&nbsp;silnoproud&nbsp;nn&nbsp;/&nbsp;DPS</li>
                    </Fade>
                    <Fade up>
                        <li>a množství dalších projektů výše uvedených technologií</li>
                    </Fade>
                </ul>

                <br />

                <div ref={contactRef} />
                <Fade up>
                    <h3>Kontakt</h3>
                </Fade>

                <div className="contact">
                    <Fade up>
                        <h4>
                            <span>Ing. Lukáš Jůzl</span>
                            <a href="https://cz.linkedin.com/in/lukasjuzl" target="_blank" rel="noreferrer noopener" className="linkedin">
                                <img src={linkedin} alt="" />
                            </a>
                        </h4>
                    </Fade>
                    <InView onChange={() => setActive('contact')} />
                    <Fade up>
                        <p><strong>Telefon</strong> {contactVisible ? <a href="tel:+420739003341">+420 739 003 341</a> : <button onClick={showContact}>zobrazit</button>}</p>
                    </Fade>
                    <Fade up>
                        <p><strong>E-mail</strong> {contactVisible ? <a href="mailto:lukas@juzl.cz">lukas@juzl.cz</a> : <button onClick={showContact}>zobrazit</button>}</p>
                    </Fade>
                    <Fade up>
                        <p><strong>IČ</strong> 08250936</p>
                    </Fade>
                    <Fade up>
                        <p><strong>Sídlo</strong> Korunní 2569/108, 101 00, Praha 10 - Vinohrady</p>
                    </Fade>
                    <Fade up>
                        <p>Fyzická osoba zapsaná v Živnostenském rejstříku</p><br />
                    </Fade>
                    <Fade up>
                        <p>Spolupráce na projektech v rámci ČR je vykonávána dálkově ze Zlína (s výjimkou osobních jednání a fyzických prohlídek stavby).</p>
                    </Fade>
                </div>

                <ReCaptcha
                    ref={reCaptchaRef}
                    sitekey="6LfSWlEdAAAAAOMMwbh0hvufmK1MK_QB8SEG9lfA"
                    onResolved={() => setContactVisible(true)}
                />
            </div>

        </main>
    )
}

export default IndexPage
